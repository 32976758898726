<template>
  <div class="flex-row border-b dark:border-warm-gray-700">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-extrabold text-warm-gray-700 tracking-wider dark:text-warm-gray-100">Create Reviewer</h2>
    </div>
  </div>
  <div class="flex justify-center">

    <!-- form wrapper -->
    <div class="flex justify-center mt-14 mb-2 text-warm-gray-600 dark:text-warm-gray-200 w-full">
      <div class=" w-10/12 lg:w-7/12">

        <!-- header 1 -->
        <div class="flex justify-between items-end">
        <div class="flex justify-start items-center pl-2">
          <h2 class="text-warm-gray-600 font-bold text-lg dark:text-warm-gray-300">Add new reviewer</h2>
        </div>
        </div>  
        <!-- Course Information Form -->
        <div class="border shadow rounded-md p-4 mt-4 dark:border-warm-gray-700">
          

          <div class="mb-4">
            <div><label for="reviewer-name" class="font-semibold">Reviewer name</label></div>
            <input type="text" id="reviewer-name" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" v-model="name" placeholder="Enter reviewer name" />
          </div>

          <div class="mb-4">
            <div><label for="reviewer-id" class="font-semibold">Shorthand / ID</label></div>
            <input type="text" id="reviewer-id" tabindex="-1" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" v-model="id" />
          </div>

          <div class="mb-4">
            <div><label for="reviewer-description" class="font-semibold">Description</label></div>
            <textarea id="reviewer-description" rows="3" v-model="description" placeholder="Enter reviewer description" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700"></textarea>
          </div>
          <div class="flex justify-end items-center mt-6">
            <button @click="$router.go(-1)" class="mr-2 bg-warm-gray-300 font-semibold py-2 px-4 rounded-md hover:bg-warm-gray-400 focus:bg-warm-gray-400 focus:outline-none dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600">Cancel</button>
            <button @click="createReviewer()" class="bg-yellow-700 text-white font-semibold py-2 px-4 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Save</button>
          </div>
        </div>
      </div>
    </div>

    <ProcessingModal v-if="isProccessing"/>
  </div>
</template>

<script setup>
import {ref,watch } from 'vue';
import { create } from '../firebase';
import ProcessingModal from '@/components/ProcessingModal';
import swal from 'sweetalert2';


const isProccessing = ref(false);

// form
const id = ref('')
const name = ref('')
const description = ref('')

watch(name, (newVal, oldVal)=>{
  // transform the new value to ID format.
  const format = /[`!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
  let newId = newVal.replaceAll(' ', '-').toLowerCase();
  newId = newId.replaceAll(format, '');
  id.value = newId;
})

const createReviewer = async () => {

  if (name.value && description.value ) {
    isProccessing.value = true;
    await create({
      id: id.value,
      name: name.value,
      description:description.value
    })
    name.value = '';
    id.value = '';
    description.value = '';
    isProccessing.value = false;
    swal.fire(
        'Success!',
        `Reviewer has been added.`,
        'success'
    );
  }else{
    alert('Please fill out all fields!');
  }

}

</script>